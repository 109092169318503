import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FlamingoAlertDialogComponent,
  FlamingoAlertDialogModel,
} from '@flamingo/component/flamingo-alert-dialog.component';
import { Observable } from 'rxjs';
import {
  FlamingoConfirmDialogComponent,
  FlamingoConfirmDialogOptions,
} from '../component/flamingo-confirm-dialog.component';
import { FlamingoInputDialogComponent, FlamingoInputDialogOptions } from '../component/flamingo-input-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FlamingoMessageService {

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
  }

  showMessage(message: string, duration: number = 5000): void {
    this.snackBar.open(message, undefined, { duration });
  }

  showMessageWithConfirm(message: string, action: string = 'OK'): void {
    this.snackBar.open(message, action);
  }

  showAlert(data: FlamingoAlertDialogModel, config?: MatDialogConfig): Observable<void> {
    return this.dialog.open(FlamingoAlertDialogComponent, { ...config, data }).afterClosed();
  }

  showModal(component: any, data: any = {}, autoFocus?: boolean, disableClose?: boolean, width?: number): Observable<any> {
    return this.dialog.open(component, {
      width: (width ? width : '600') + 'px',
      maxHeight: '95vh',
      maxWidth: '90vw',
      autoFocus,
      disableClose,
      data,
    }).afterClosed();
  }

  showConfirmDialog(options: FlamingoConfirmDialogOptions, config?: MatDialogConfig): Observable<boolean> {
    return this.dialog.open(FlamingoConfirmDialogComponent, {
      ...config,
      data: options,
    }).afterClosed();
  }

  showInputDialog(options: FlamingoInputDialogOptions, config?: MatDialogConfig): Observable<string> {
    return this.dialog.open(FlamingoInputDialogComponent, {
      ...config,
      data: options,
    }).afterClosed();
  }

}
