import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-flamingo-confirm-dialog',
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <div mat-dialog-content>
      <p [innerHtml]="data.description"></p>
    </div>
    <div mat-dialog-actions [align]="'end'">
      <button [tabIndex]="-1" type="button" *ngIf="data.cancel" mat-button
              [mat-dialog-close]="undefined">{{ data.cancel }}
      </button>
      <button mat-flat-button [mat-dialog-close]="true" class="btn-margin">{{ data.confirm }}</button>
    </div>
  `,
  standalone: false,
})
export class FlamingoConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FlamingoConfirmDialogOptions>,
    @Inject(MAT_DIALOG_DATA) public data: FlamingoConfirmDialogOptions) {
  }

  close(res: boolean): void {
    this.dialogRef.close(res);
  }
}

export interface FlamingoConfirmDialogOptions {
  title?: string;
  description?: string;
  confirm?: string;
  cancel?: string;
}
